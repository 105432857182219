import api from "@/api-handler";

const clients = {
  namespaced: true,

  state: {
    clients: null,
  },

  getters: {
    clients: (state) => {
      return state.clients;
    },
  },

  mutations: {
    setClients: (state, clients) => {
      state.clients = clients;
    },

  },

  actions: {
    async getClients({ commit }) {
      try {
        const { data } = await api.get('/clients/list');
        commit('setClients', data);
        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async update({ dispatch }, payload) {
      try {
        await api.put('/clients/update', payload);

        dispatch('getClients');

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async add({ dispatch }, payload) {
      try {
        await api.post('/clients/add/', payload);
        dispatch('getClients');

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async delete({ dispatch }, clientId) {
      try {
        await api.delete('/clients/delete/' + clientId);
        dispatch('getClients');

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

  }
};

export default clients;
