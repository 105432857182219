import api from "@/api-handler";

const admins = {
  namespaced: true,

  state: {
    admins: null,
  },

  getters: {
    admins: (state) => {
      return state.admins;
    },
  },

  mutations: {
    setAdmins: (state, admins) => {
      state.admins = admins;
    },

  },

  actions: {
    async getAdmins({ commit }) {
      try {
        const { data } = await api.get('admin/all');
        commit('setAdmins', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

  }
};

export default admins;
