<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 22.75V22.75C7.61475 22.75 3.25 18.3853 3.25 13V13C3.25 7.61475 7.61475 3.25 13 3.25V3.25C18.3853 3.25 22.75 7.61475 22.75 13V13C22.75 18.3853 18.3853 22.75 13 22.75Z"
      stroke="#30750E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3334 10.8334L11.9167 16.25L8.66675 13"
      stroke="#30750E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
};
</script>
