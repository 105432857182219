<template>
  <div class="bg-black">
    <img src="../svg/logo.svg" alt="LOGO" />
  </div>
</template>

<script>
export default {
  name: 'Logo'
}
</script>