import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from "@/store"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta: {
      doesntRequireAuth: true,
    },
  },
  {
    path: '/bienvenida',
    name: 'Bienvenida',
    component: () => import(/* webpackChunkName: "Bienvenida" */ '../views/Bienvenida.vue'),
    meta: {
      doesntRequireAuth: true,
    },
  },
  // Rutas Director/Coordinador
  {
    path: '/director',
    name: 'Director',
    component: () => import(/* webpackChunkName: "Director" */ '../views/director/Director.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/ingreso-trabajo',
    name: 'IngresoTrabajo',
    component: () => import(/* webpackChunkName: "IngresoTrabajo" */ '../views/director/IngresoTrabajo.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/evaluacion-semanal',
    name: 'EvaluacionSemanal',
    component: () => import(/* webpackChunkName: "EvaluacionSemanal" */ '../views/director/EvaluacionSemanal.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/pendientes',
    name: 'Pendientes',
    component: () => import(/* webpackChunkName: "Pendientes" */ '../views/director/Pendientes.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/ingresar-cliente',
    name: 'IngresarCliente',
    component: () => import(/* webpackChunkName: "IngresarCliente" */ '../views/director/ingresar-cliente/IngresarCliente.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/ingresar-cliente/clientes-actuales',
    name: 'ClientesActuales',
    component: () => import(/* webpackChunkName: "ClientesActuales" */ '../views/director/ingresar-cliente/ClientesActuales.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  {
    path: '/director/ingresar-cliente/ingresar-nuevo',
    name: 'IngresarNuevo',
    component: () => import(/* webpackChunkName: "IngresarNuevo" */ '../views/director/ingresar-cliente/IngresarNuevo.vue'),
    meta: {
      authorizedRole: 'DIRECTOR',
    },
  },
  // Rutas Creativo/Diseñador/Fogógrafo/Animador
  {
    path: '/recurso',
    name: 'Recurso',
    component: () => import(/* webpackChunkName: "Recurso" */ '../views/recurso/Recurso.vue')
  },
  {
    path: '/recurso/trabajos',
    name: 'Trabajos',
    component: () => import(/* webpackChunkName: "Trabajos" */ '../views/recurso/Trabajos.vue')
  },
  {
    path: '/recurso/trabajos-del-dia',
    name: 'TrabajosDelDia',
    component: () => import(/* webpackChunkName: "TrabajosDelDia" */ '../views/recurso/TrabajosDelDia.vue')
  },
  // Rankings (ambos roles)
  {
    path: '/rankings/',
    name: 'Rankings',
    component: () => import(/* webpackChunkName: "Rankings" */ '../views/Rankings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

import { isDirector } from "@/utils/roles"

router.beforeEach((to, from, next) => {
  var user = store.getters['user/user'];
  const doesntReqAuth = to.meta.doesntRequireAuth;

  if (doesntReqAuth) {
    return next()
  } 

  if (!user) {
    return validateUser()
  } else {
    return verifyUserRoles(user.roles)
  }

  function validateUser() {
    store.dispatch('user/getUser').then(() => {
      user = store.getters['user/user']
      if (!user) {
        return next({ name: 'Login' });
      } else {
        return verifyUserRoles(user.roles)
      }
    });
  }

  function verifyUserRoles(roles) {
    const authorizedRole = to.meta.authorizedRole

    if (authorizedRole === 'DIRECTOR' && !isDirector(roles)) {
      return next({ name: 'Recurso' });
    }

    return next();
  }
});

export default router
