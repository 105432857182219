import Vue from 'vue'
// import './plugins/axios.ts.pepino'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import './registerServiceWorker'
import './styles/app.css'
import './styles/custom.css'
import './styles/fonts.css'

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
