import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
Vue.use(Vuex)

// import modules from './modules';

import user from '@/store/modules/user.js'
import admins from '@/store/modules/admins.js'
import clients from '@/store/modules/api-modules/clients.js'
import employees from '@/store/modules/employees.js'
import workTypes from '@/store/modules/workTypes.js'
import difficulties from '@/store/modules/difficulties.js'

import works from '@/store/modules/api-modules/works.js'
import evaluations from '@/store/modules/api-modules/evaluations.js'

import ingresoTrabajo from '@/store/modules/ingresoTrabajo.js'
import evaluacionSemanal from '@/store/modules/evaluacion-semanal.js'
import pendientes from '@/store/modules/pendientes.js'
import reports from '@/store/modules/reports.js'
import clientesActuales from '@/store/modules/clientesActuales.js'
import ingresarNuevoCliente from '@/store/modules/ingresarNuevoCliente.js'
import layout from '@/store/modules/layout.js'

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0'
  },
  modules: {
    user,
    admins,
    clients,
    employees,
    workTypes,
    difficulties,

    works,
    evaluations,

    ingresoTrabajo,
    evaluacionSemanal,
    pendientes,
    reports,
    clientesActuales,
    ingresarNuevoCliente,
    layout,
  }
}

export default new Vuex.Store<RootState>(store)
