import axios from 'axios';
import store from '@/store';
import router from '@/router';
import eventBus from '@/plugins/event-bus';


const instance = axios.create({
  baseURL: process.env.API_ENDPOINT || '/',
  withCredentials: true,
});

instance.interceptors.request.use(
  request => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken)
      request.headers.common['Authorization'] = 'Bearer ' + accessToken;

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status == 401) {
      //auth error
      store.commit('user/logout');
      router.push({ name: 'Login' });
    }

    if (error.response.status == 403) {
      return Promise.reject(new Error('Ruta no permitida'));
    }
    // if (error.response.status == 404) {
    //   return Promise.reject(new Error('Ruta no encontrada'));
    // }

    const message = getErrorMessageFrom(error);

    if (instance.rtd_allow_error_toast) {
      eventBus.$emit('toast', { message });
    } else {
      // If set to false, it resets itself after, so it's deactivated in a per-request basis
      instance.rtd_allow_error_toast = true;
    }

    return Promise.reject(new Error(message));
  }
);

function getErrorMessageFrom(error) {
  const { response } = error;
  if (!response || !response.data) {
    return error.message || error;
  }

  const { data } = response;
  if (!data.message) {
    return data;
  }

  return data.message;
}

instance.rtd_allow_error_toast = true;

export default instance;
