import api from "@/api-handler";

const workTypes = {
  namespaced: true,

  state: {
    workTypes: null,
  },

  getters: {
    workTypes: (state) => {
      return state.workTypes;
    },
  },

  mutations: {
    setWorkTypes: (state, workTypes) => {
      state.workTypes = workTypes;
    },

  },

  actions: {
    async getWorkTypes({ commit }) {
      try {
        const { data } = await api.get('worktype/all');
        commit('setWorkTypes', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

  }
};

export default workTypes;
