<template>
  <div
    class="toast"
    :class="[isSuccess ? 'bg-green-500 text-green-900' : 'bg-red-500 text-white']"
    :style="transform"
  >
    <span class="close-icon" @click="closeToast"
      ><CloseIcon :color="isSuccess ? '#357E11' : 'white'" :size="18"
    /></span>
    <span>
      <CheckIcon v-if="isSuccess" />
      <WarningIcon v-else />
    </span>
    <div class="pr-2">
      {{ text }}
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/shared/icons/CloseIcon';
import CheckIcon from '@/components/shared/icons/CheckIcon';
import WarningIcon from '@/components/shared/icons/WarningIcon';
export default {
  name: 'Toast',
  components: { CloseIcon, CheckIcon, WarningIcon },
  data() {
    return {
      translateAmount: 150,
      toastDuration: 8000,
      isSuccess: false,
      text: '',
    };
  },
  computed: {
    transform() {
      return `transform: translateY(${this.translateAmount}%)`;
    },
  },

  methods: {
    closeToast() {
      this.translateAmount = 150;
    },

    show({ success, message }) {
      this.isSuccess = success;
      if (message) {
        this.text = message;
      } else {
        this.text = success ? 'Cambios guardados con éxito.' : 'Ocurrió un error, por favor intenta de nuevo más tarde.';
      }

      this.translateAmount = 0;
      setTimeout(() => {
        this.closeToast();
      }, this.toastDuration);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  transition: transform 0.4s ease-out;
  @apply fixed bottom-5 right-5 rounded-lg flex items-center gap-5 p-5 w-80 text-sm z-50;

  .close-icon {
    @apply absolute top-2 right-2 cursor-pointer;
  }
}
</style>
