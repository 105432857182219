import api from "@/api-handler";

const reports = {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    user: (state) => {
      return state.user;
    },
  },

  mutations: {},

  actions: {
    async getGeneralMonthlyReport(_, { from, to }) {
      try {
        const { data } = await api.post('/Reports/GeneralMonthlyReport', { from, to });
        return { success: true, data };
      } catch (error) {
        return { success: false, message: error };
      }
    },

    async getDirectorMonthlyReport(_, { from, to }) {
      try {
        const { data } = await api.post('/Reports/DirectorMonthlyReport', { from, to });
        return { success: true, data };
      } catch (error) {
        return { success: false, message: error };
      }
    },


  }
};

export default reports;
