import api from "@/api-handler";

const evaluations = {
  namespaced: true,

  actions: {
    async add(_, payload) {
      try {
        await api.post('evaluations/add', payload);
        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },
  }
};

export default evaluations;
