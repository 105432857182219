const evaluacionSemanal = {
  namespaced: true,

  state: {
    recurso: null,
    disponible: null,
    cumplimiento: null,
    autosuficiente: null,
    bienHecho: null,
    aTiempo: null,
    eficiencia: null
  },

  getters: {
    recurso: (state) => {
      return state.recurso
    },
    disponible: (state) => {
      return state.disponible
    },
    cumplimiento: (state) => {
      return state.cumplimiento
    },
    autosuficiente: (state) => {
      return state.autosuficiente
    },
    bienHecho: (state) => {
      return state.bienHecho
    },
    aTiempo: (state) => {
      return state.aTiempo
    },
    eficiencia: (state) => {
      return state.eficiencia
    }
  },

  mutations: {
    setRecurso: (state, recurso) => {
      state.recurso = recurso
    },
    setDisponible: (state, disponible) => {
      state.disponible = disponible
    },
    setCumplimiento: (state, cumplimiento) => {
      state.cumplimiento = cumplimiento
    },
    setAutosuficiente: (state, autosuficiente) => {
      state.autosuficiente = autosuficiente
    },
    setBienHecho: (state, bienHecho) => {
      state.bienHecho = bienHecho
    },
    setATiempo: (state, aTiempo) => {
      state.aTiempo = aTiempo
    },
    setEficiencia: (state, eficiencia) => {
      state.eficiencia = eficiencia
    }
  }
}

export default evaluacionSemanal