<template>
  <div class="bg-black">
    <div class="app-background">
      <div class="app-container" :class="{ 'main-layout': activeLayout === 'MainLayout' }">
        <HeaderWithLogo v-if="activeLayout === 'MainLayout'" />
        <div
          :class="{ 'p-3': activeLayout === 'MainLayout', 'bg-dark': $route.name === 'Rankings' }"
        >
          <transition name="opacity" mode="out-in">
            <router-view :key="$route.path"></router-view>
          </transition>
        </div>
        <p
          v-if="showLogoutButton"
          class="fixed bottom-1 right-2 px-3 py-2 font-bold"
          @click="signOut"
        >
          Logout
        </p>
      </div>

      <Toast ref="toast" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import HeaderWithLogo from '@/components/shared/HeaderWithLogo.vue'
import eventBus from '@/plugins/event-bus'
import Toast from '@/components/shared/Toast.vue'
export default {
  name: 'App',
  components: { HeaderWithLogo, Toast },
  computed: {
    ...mapGetters({ activeLayout: 'layout/activeLayout' }),
    showLogoutButton() {
      return this.activeLayout === 'MainLayout' && this.$route.name !== 'Rankings'
    }
  },

  mounted() {
    eventBus.$on('toast', this.showToast)
  },

  methods: {
    ...mapMutations({ logout: 'user/logout' }),

    signOut() {
      this.logout()
      this.$router.push({ name: 'Login' })
    },

    showToast({ success, message, error }) {
      this.$refs.toast.show({ success, message, error })
    }
  }
}
</script>

<style scoped>
.app-background {
  @apply flex items-center justify-center w-full min-h-screen;
}

@media (min-width: 468px) {
  .app-background {
    background: url('./assets/desktop-background.png') no-repeat;
    background-size: cover;
  }
}

.app-container {
  @apply w-full h-screen;
  max-width: 378px;
  overflow-y: auto;
}

.main-layout {
  @apply min-h-screen sm:min-h-0;
  background-color: #f2f2f2;
}
.bg-dark {
  background: #171717;
}
/* Opacity Animation */

.opacity-enter-active {
  animation: opacity-in 300ms ease-out forwards;
}
.opacity-leave-active {
  animation: opacity-out 300ms ease-out forwards;
}
@keyframes opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacity-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
