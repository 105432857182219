const layout = {
  namespaced: true,

  state: {
    activeLayout: 'MainLayout'
  },

  getters: {
    activeLayout: (state) => {
      return state.activeLayout
    }
  },

  mutations: {
    setActiveLayout: (state, activeLayout) => {
      state.activeLayout = activeLayout
    }
  }
}

export default layout
