<template>
  <div>
    <img src="../svg/warning-icon.svg" alt="Alerta" />
  </div>
</template>

<script>
export default {
  name: 'WarningIcon'
}
</script>