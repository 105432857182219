import api from "@/api-handler";

const works = {
  namespaced: true,

  state: {
    worksAssigned: null,
  },

  getters: {
    worksAssigned: state => state.worksAssigned

  },

  mutations: {
    setWorksAssigned: (state, worksAssigned) => {
      state.worksAssigned = worksAssigned;
    },
    setStatus: (state, payload) => {
      const index = state.worksAssigned.findIndex(work => work.id === payload.id);
      state.worksAssigned[index].status = payload.status;
    }
  },

  actions: {
    async add(_, payload) {
      try {
        await api.post('works/add', payload);
        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },
    async deleteWork(_, workId) {
      try {
        await api.delete('works/Delete/' + workId);
        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async getAssigned({ commit }) {
      try {
        const { data } = await api.get('works/assigned');
        commit('setWorksAssigned', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async setAsFinish({ dispatch }, workId) {
      try {
        const data = await api.put('works/setAsFinish/' + workId);
        dispatch('getAssigned');

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async setAsPartialFinish({ dispatch }, workId) {
      try {
        const data = await api.put('works/setAsPartialFinish/' + workId);
        dispatch('getAssigned');

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },
  }
};

export default works;
