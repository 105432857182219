import api from "@/api-handler";

const difficulties = {
  namespaced: true,

  state: {
    difficulties: null,
  },

  getters: {
    difficulties: (state) => {
      return state.difficulties;
    },
  },

  mutations: {
    setDifficulties: (state, difficulties) => {
      state.difficulties = difficulties;
    },

  },

  actions: {
    async getDifficulties({ commit }) {
      try {
        const { data } = await api.get('difficulty/all');
        commit('setDifficulties', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

  }
};

export default difficulties;
