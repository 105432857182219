const ingresoTrabajo = {
  namespaced: true,

  state: {
    recurso: null,
    cliente: null,
    palabraClave: '',
    subPalabraClave: '',
    dificultad: null,
    deadline: null,
    admin: null,
    tipo: null
  },

  getters: {
    recurso: state => state.recurso,
    cliente: state => state.cliente,
    palabraClave: state => state.palabraClave,
    subPalabraClave: state => state.subPalabraClave,

    dificultad: state => state.dificultad,
    admin: state => state.admin,
    tipo: state => state.tipo,
    deadline: state => state.deadline,
  },

  mutations: {
    setRecurso: (state, recurso) => {
      state.recurso = recurso;
    },
    setCliente: (state, cliente) => {
      state.cliente = cliente;
    },
    setPalabraClave: (state, { property, value }) => {
      state[property] = value;
    },
    setDificultad: (state, dificultad) => {
      state.dificultad = dificultad;
    },
    setAdmin: (state, admin) => {
      state.admin = admin;
    },
    setTipo: (state, tipo) => {
      state.tipo = tipo;
    },
    setDeadline: (state, deadline) => {
      state.deadline = deadline;
    }
  }
};

export default ingresoTrabajo;
