import api from "@/api-handler";

const employees = {
  namespaced: true,

  state: {
    employees: null,
    usersWithPendingWorks: null,
  },

  getters: {
    employees: (state) => state.employees,
    usersWithPendingWorks: (state) => state.usersWithPendingWorks,
  },

  mutations: {
    setEmployees: (state, employees) => {
      state.employees = employees;
    },
    setUsersWithPendingWorks: (state, users) => {
      state.usersWithPendingWorks = users;
    },
  },

  actions: {
    async getEmployees({ commit }) {
      try {
        const { data } = await api.get('users/employees');
        commit('setEmployees', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async getUsersWithPendingWorks({ commit }) {
      try {
        const { data } = await api.get('users/UsersWithPendingWorks');
        commit('setUsersWithPendingWorks', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },
  }
};

export default employees;
