<template>
  <div class="w-full bg-black flex justify-center py-2" @click="goHome">
    <Logo class="w-36" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isDirector } from '@/utils/roles'
import Logo from '@/components/shared/icons/Logo.vue'
export default {
  name: 'HeaderWithLogo',
  components: { Logo },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    goHome() {
      if (isDirector(this.user.roles)) {
        this.$router.push({ name: 'Director' })
      } else {
        this.$router.push({ name: 'Recurso' })
      }
    }
  }
}
</script>
