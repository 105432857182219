const directorRoles = ['administrador', 'coordinardor', 'director', 'productor',]
// const recursoRoles = ['diseñador', 'redactor']

function evaluateRole(usrRoles, list) {
  for (const rol of usrRoles) {
    if (list.includes(rol.toLowerCase())) {
      return true
    }
  }
  return false
}

function isDirector(roles) {
  return evaluateRole(roles, directorRoles)
}

// function isRecurso(roles) {
//   return evaluateRole(roles, recursoRoles)
// }

export {
  isDirector,
  // isRecurso
}
