import api from "@/api-handler";

const user = {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    user: (state) => {
      return state.user;
    },
  },

  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },

    logout: (state) => {
      state.user = null;
      localStorage.setItem('accessToken', null);
    }
  },

  actions: {
    async getUser({ commit }, preventErrorToastOnce) {
      try {
        if (!localStorage.getItem('accessToken')) {
          return { success: false };
        }

        if (preventErrorToastOnce) {
          api.rtd_allow_error_toast = false;
        }

        const { data } = await api.post('/users/getme');

        commit('setUser', {
          id: data.id,
          mail: data.mail,
          roles: data.roles,
          canSendWorks: data.canSendWorks
        });

        return { success: true };

      } catch (error) {
        commit('logout');
        return { success: false, message: error };
      }
    },

    async login({ commit }, form) {
      try {
        const { data } = await api.post('/login/login', form);
        commit('setUser', {
          id: data.id,
          mail: data.userData.mail,
          roles: data.userData.roles,
          canSendWorks: data.userData.canSendWorks
        });

        localStorage.setItem('accessToken', data.token);
        return { success: true };
      } catch (error) {
        return { success: false, message: error };
      }
    },


  }
};

export default user;
