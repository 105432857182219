const ingresarNuevoCliente = {
  namespaced: true,

  state: {
    cliente: null,
    admin: null,
  },

  getters: {
    cliente: (state) => {
      return state.cliente
    },
    admin: (state) => {
      return state.admin
    },
  },

  mutations: {
    setCliente: (state, cliente) => {
      state.cliente = cliente
    },
    setAdmin: (state, admin) => {
      state.admin = admin
    },
  }
}

export default ingresarNuevoCliente
