import api from "@/api-handler";

const pendientes = {
  namespaced: true,

  state: {
    recurso: null,
    userPendingWorks: null,
  },

  getters: {
    recurso: (state) => state.recurso,
    userPendingWorks: (state) => state.userPendingWorks
  },

  mutations: {
    setRecurso: (state, recurso) => {
      state.recurso = recurso;
    },
    setUserPendingWorks: (state, userPendingWorks) => {
      state.userPendingWorks = userPendingWorks;
    },
  },

  actions: {
    async getUserPendingWorks({ commit }, userId) {
      try {
        const { data } = await api.get('works/UserPendingWorks/' + userId);
        commit('setUserPendingWorks', data);

        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },

    async assignUserToWork(_, { UserId, WorkId }) {
      try {
        await api.put('works/AssignUser/', { UserId, WorkId });
        return { success: true };

      } catch (error) {
        return { success: false, message: error };
      }
    },
  }
};

export default pendientes;
